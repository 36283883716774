import React, { Component } from 'react';
import VideoFile from './classinfo.json'
import {Container, Row, Col, ListGroup} from 'react-bootstrap'
import VideoModal from "./VideoModal"
// import 'react-pro-sidebar/dist/css/styles.css';
import './main.css'
class App extends Component {
  
  constructor(){
    super()
    this.state = {
      videoId: "349306089",
      cohortId: "30"
    }
  }

  handleClick =(e)=>{
    e.preventDefault();
    console.log(e.target.id)
    console.log(e.target.dataset.video)
    this.setState({videoId: e.target.dataset.video, cohortId:e.target.id})

  }
  render() {
    let videolisting = VideoFile.map((item, index) => (<ListGroup.Item key={index} className="listgroup-dark"><div className="d-grid gap-2 p-0"><button onClick={this.handleClick} id={item.cohort} data-video={item.video} className="btn-light btn-sm btn-text" type="button">{item.cohort}</button>
    </div></ListGroup.Item>))
    return (
   

<div>
      <Container>
        <h1>Graduation Videos</h1>
      <p className="footer"><span className="definition">SEI </span>- Software Engineering Immersive | <span className="definition">Py/DS</span>- Python/Data Science  | <span className="definition">FEWD</span> - FrontEnd Web Development. Videos created by: Paula Bannerman</p> 
      <hr></hr>
      <Row className="g-5">
      {VideoFile.map((item, index)=> 
        (<Col className="col-12 col-md-4 col-sm-12">
        <VideoModal {...item}></VideoModal>

        </Col>)
      ).reverse()}

      </Row>

      </Container>
 {/* <Container fluid className="homebase">
  
        <Row> 
        <Col xs={12} lg={9} md={12} className="videoSize">    <p className="footer"><span className="definition">SEI </span>- Software Engineering Immersive | <span className="definition">DSI</span>- Data Science Immersive  | <span className="definition">FEWD</span> - FrontEnd Web Development. Videos created by: Paula Bannerman</p> 
        
        <Video {...this.state} ></Video>
     
        </Col>
    <Col xs={12} lg={3} md={12}><p className="footer definition">Cohort: {this.state.cohortId} </p><ListGroup variant="flush">
   
    {videolisting}</ListGroup></Col>
        </Row>
      </Container> */}
      {/* <nav className='navigation'></nav> */}
     
      {/* <div className="footer"><p>SEI - Software Engineering Immersive |  DSI - Data Science Immersive  | FEWD - FrontEnd Web Development</p>Videos created by: Paula Bannerman</div> */}
      </div>
    );
  }
}

export default App;