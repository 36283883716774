import React from 'react'
import ReactPlayer from 'react-player'
// import { Player } from 'video-react';
// import "./video-react.css"; 
// import "node_modules/video-react/dist/video-react.css"; 
export default function VideoPlayer(props) {
    let videoUrl = `https://player.vimeo.com/video/${props.videoId}`
    // return <ReactPlayer url={videoUrl} controls="true" width="100%"/>
    return  <div className="ratio ratio-16x9">
    <iframe
      src={videoUrl}
      // title="YouTube video"
      allowfullscreen
      allow="fullscreen" 
    ></iframe>
  </div>
    
  //   <div className="player-wrapper">
  //   <ReactPlayer
  //     url={videoUrl}
  //     className="react-player"
  //     playing = {false}
  //     width="100%"
  //     height="100%"
  //     controls={true}
  //   />
  // </div>
//     return  <div className="embed-responsive">
//     <iframe className="embed-responsive-item vp-video-wrapper" src={videoUrl} allowfullscreen></iframe>
//   </div>
}
