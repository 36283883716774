import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';


import Video from './VideoPlayer'
export default function VideoModal(props) {
  console.log(props)
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [fullscreen, setFullscreen] = useState(false);
  return (
    <div>
    <Button variant="primary" onClick={handleShow} className="btn btn-dark w-100 p-4 h-100">
      <p>{props.cohort}</p>
    </Button>

    <Modal show={show} onHide={handleClose} className="w-100"
    size="xl"
    fullscreen={fullscreen}
    >
 <Modal.Header>
  <h2 className="centered-h2">{props.cohort}</h2>
 </Modal.Header>
      <Modal.Body>
        
      <Video {...props} videoId={props.video} ></Video>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
  )
}
